import { Box, Breakpoint, Container } from "@mui/material";
import React from "react";
import { Image } from "../types";
import Carousel from "../components/organisms/Carousel";

type LayoutProps = {
  title?: string;
  images?: Array<Image>;
  children?: React.ReactNode;
  actionComponent?: React.ReactNode;
  maxWidth?: Breakpoint | false;
  loading?: boolean;
};
const Layout: React.FC<LayoutProps> = ({ maxWidth, children, images }) => {
  // const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;
  return (
    <>
      {images && images.length === 1 ? (
        <Box
          {...(images[0] && {
            sx: {
              backgroundImage: `url(${images[0].src})`,
              backgroundPosition: "top",
              backgroundSize: "cover",
            },
          })}
        >
          <Container
            maxWidth={maxWidth}
            sx={{
              height: "100vh",
              marginBottom: 2,
              bgcolor: "transparent",
            }}
          >
            {children && children}
          </Container>
        </Box>
      ) : (
        <Box mb={3}>
          <Carousel images={images} disableArrows>
            <Container
              maxWidth={maxWidth}
              sx={{
                height: "100%",
                position: "absolute",
                display: "flex",
                flexDirection: "column",
                marginBottom: 2,
                justifyContent: "flex-end",
                bgcolor: "transparent",
                top: 0,
                left:0
              }}
            >

              {children && children}
            </Container>
          </Carousel>
        </Box>
      )}
    </>
  );
};

export default Layout;
