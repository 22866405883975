import { Grid } from "@mui/material";
import Layout from "../../molecules/Layout";
import Section from "../../molecules/Section";
import Typography from "../molecules/Typography";

export default function Volt() {
  // const theme = useTheme();
  // const matchesDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;
  return (
    <>
      <Layout
        images={[
          {
            src: `${S3_BUCKET_URL}volt/cover-volt.png`,
            alt: "volt",
          },
        ]}
        maxWidth="xl"
      >
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={12} md={6} sx={{ marginTop: 12 }}>
            <Typography fontFamilyStr="Medium" paragraph variant="body1">
              {
                "VOLT is one of the most important clubs on the world avant-garde electronic scene, based in Milan. It was founded by Claudio Antonioli with the aim of putting Milan on the map of the most important places for electronic music, hosting the biggest names in the sector. Dixon, Tale Of Us, Rampa, &ME, Adam Port, Apparat, Adriatique, Âme, Fideles, Agents Of Time, Dj Tennis, Jimi Jules, Gerd Janson, Mind Against, Laurent Garnier are just some of the artists who have performed at Volt in the past years."
              }
            </Typography>
          </Grid>
        </Grid>
      </Layout>
      <Section
        rtl
        title={"DESIGN"}
        images={[
          {
            src: `${S3_BUCKET_URL}volt/design.png`,
            alt: "Design",
            caption: "Volt Club",
          },
        ]}
        maxWidth={"xl"}
      >
        <Typography paragraph variant="body1">
          {
            "The interior design project was carried out in collaboration with the Milanese architecture studio Storage Associati. The concept behind the Volt project was to operate in various areas, from design to construction of unique and exclusive architectural ideas and installations for the national and international nightlife sector. Architecture, design, fashion, art and everything that embodies shape and volume are the main interest of the challenge experimenting with unusual or different materials, with light and space, attention to detail and elegance are what surround and inspire us today"
          }
        </Typography>
      </Section>
      <Section
        images={[
          {
            src: `${S3_BUCKET_URL}volt/tale-of-us.png`,
            alt: "Tale of us",
            caption: "Tale of Us, Volt",
          },
        ]}
        maxWidth={"xl"}
      />
      <Section rtl half title={"VTL AGENCY"} maxWidth={"xl"}>
        <Typography paragraph variant="body1">
          {
            "Within the VOLT eco-system there is also VLT Agency, a project that has its roots in music. The agency reflects the combination of passion for music and a sense of responsibility for the role that internationally renowned artists play today, such as those represented by VLT Agency, inspired by our past and projected into the future."
          }
        </Typography>
      </Section>
      <Section
        images={[
          {
            src: `${S3_BUCKET_URL}volt/agency.png`,
            alt: "Agency",
          },
        ]}
        maxWidth={"xl"}
      />
    </>
  );
}
