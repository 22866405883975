import { Box } from "@mui/material";
import Section from "../../molecules/Section";
import Typography from "../molecules/Typography";

export default function NotFound() {

  return (
      <Section maxWidth="xl">
        <Box
          display={"flex"}
          height={"100vh"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography variant='h1' fontFamilyStr="Black">{"404"}</Typography>
          <Typography variant='body1'>{"Page not found"}</Typography>
        </Box>
      </Section>
  );
}
