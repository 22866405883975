import Layout from "../../molecules/Layout";
import Section from "../../molecules/Section";
import Typography from "../molecules/Typography";

export default function AnnDemeulemeester() {
  // const theme = useTheme();
  // const matchesDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;
  return (
    <>
      <Layout
        images={[
          {
            src: `${S3_BUCKET_URL}ann-demeuelemeester/ann-cover.png`,
          },
        ]}
        maxWidth="xl"
      />
      <Section
        images={[
          {
            src: `${S3_BUCKET_URL}ann-demeuelemeester/ann-demeuelemeester.png`,
            alt: "ann-demeuelemeester",
          },
        ]}
        maxWidth={"xl"}
      >
        <Typography paragraph variant="body1">
          {
            "Ann Demeulemeester — With a career spanning more than three decades, Ann Demeulemeester is one of the world’s most respected creative voices. An instrumental member of the Antwerp Six designers, who loaded a van with their collections and stormed London fashion week in 1986, changing the international reputation of Belgian design "
          }
        </Typography>
        <Typography paragraph variant="body1">
          {
            "The Ann Demeulemeester brand has come to represent an intelligent approach to fashion – rising above trends and instead offering a continually evolving system of dressing that is both deeply personal to the wearer and profoundly relevant to its context – thanks to its integrity and fierce independence. "
          }
        </Typography>
        <Typography paragraph variant="body1">
          {
            "Working across fashion, and through close collaborations with photographers, musicians, curators and artists, the brand has cultivated a distinctive aesthetic and a loyal following. "
          }
        </Typography>
      </Section>
      <Section
        rtl
        title={"NEW BEGINNINGS"}
        images={[
          {
            src: `${S3_BUCKET_URL}ann-demeuelemeester/new-beginnings.png`,
            alt: "New beginnings",
          },
        ]}
        maxWidth={"xl"}
      >
        <Typography variant="body1">
          {
            "In 2020 Ann Demeulemeester welcomes Antonioli, who takes over the brand with great respect for the past and a clear vision for the future. "
          }
        </Typography>
        <Typography paragraph variant="body1">
          {
            "His mission, to reposition Ann Demeulemeester as a High-end Luxury Designer brand, while staying faithful to its strong brand image established over the years, begins with the Autumn/Winter 2021-2022 collection. "
          }
        </Typography>
      </Section>

      <Section
        images={[
          {
            src: `${S3_BUCKET_URL}ann-demeuelemeester/pitti-uomo-22.png`,
            alt: "Pitti uomo 22",
            caption:
              "Pitti Immagine Uomo 102, Guest of Honor in Florence - June 2022",
          },
        ]}
        maxWidth={"xl"}
      />
      <Section
        title={"STEFANO GALLICI"}
        images={[
          {
            src: `${S3_BUCKET_URL}ann-demeuelemeester/stefano-gallici.png`,
            alt: "stefano-gallici",
          },
        ]}
        maxWidth={"xl"}
      >
        <Typography paragraph variant="body1">
          {
            "Stefano Gallici was appointed Creative Director of the maison in June 2023 and has already presented two Spring/ Summer and Autumn winter collections. His vision of the brand is nourished by a mixture of musical leanings and respect for Ann’s work, synthesized in a vibrant and individual signature. "
          }
        </Typography>
      </Section>
      <Section
        images={[
          {
            src: `${S3_BUCKET_URL}ann-demeuelemeester/spring-summer-24.png`,
            alt: "spring-summer-24",
            caption: "Spring / Summer 2024",
          },
        ]}
        maxWidth={"xl"}
      />
      <Section
        half
        rtl
        images={[
          {
            src: `${S3_BUCKET_URL}ann-demeuelemeester/autumn-winter-24-1.png`,
            alt: "autumn-winter-24",
            caption: "Autumm / Winter 2024",
          },
        ]}
        maxWidth={"xl"}
      />
      <Section
        images={[
          {
            src: `${S3_BUCKET_URL}ann-demeuelemeester/autumn-winter-24-2.png`,
            alt: "autumn-winter-24",
            caption: "Autumm / Winter 2024",
          },
        ]}
        maxWidth={"xl"}
      />
      <Section
        images={[
          {
            src: `${S3_BUCKET_URL}ann-demeuelemeester/neuf-voix.png`,
            alt: "neuf-voix",
            caption:
              "Neuf Voix during Autumm/ Winter 2024 Fashion Show - Espace des Blancs Manteux, Paris.",
          },
        ]}
        maxWidth={"xl"}
      />
      <Section
        images={[
          {
            src: `${S3_BUCKET_URL}ann-demeuelemeester/people.png`,
            alt: "People",
          },
        ]}
        maxWidth={"xl"}
      />
      <Section
        half
        images={[
          {
            src: `${S3_BUCKET_URL}ann-demeuelemeester/the-eclipse-of-you-1.png`,
            alt: "the-eclipse-of-you",
            caption:
              "“The Eclipse of You” Limited Edition Vinyl released to celebrate Debut Collection.",
          },
        ]}
        maxWidth={"xl"}
      />
      <Section
        images={[
          {
            src: `${S3_BUCKET_URL}ann-demeuelemeester/the-eclipse-of-you-2.png`,
            alt: "the-eclipse-of-you",
            caption:
              "“The Eclipse of You” Limited Edition Vinyl released to celebrate Debut Collection.",
          },
        ]}
        maxWidth={"xl"}
      />
      <Section
        images={[
          {
            src: `${S3_BUCKET_URL}ann-demeuelemeester/antwerp-flagship-store.png`,
            alt: "antwerp-flegship-store",
            caption: "Antwerp Flagship Store",
          },
        ]}
        maxWidth={"xl"}
      />
      <Section
        images={[
          {
            src: `${S3_BUCKET_URL}ann-demeuelemeester/accessoriies-parfumes-footweare.png`,
            alt: "accessoriies-parfumes-footweare",
          },
        ]}
        maxWidth={"xl"}
      />
    </>
  );
}
