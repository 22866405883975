import Layout from "../../molecules/Layout";
import Section from "../../molecules/Section";
import Typography from "../molecules/Typography";

export default function LabelGroup() {
  // const theme = useTheme();
  // const matchesDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;
  return (
    <>
      <Layout
        images={[
          {
            src: `${S3_BUCKET_URL}44-label-group/cover.png`,
            alt: "44-label-grou",
          },
        ]}
        maxWidth="xl"
      />
      <Section half rtl maxWidth={"xl"}>
        <Typography paragraph variant="body1">
          {
            "Launched in June 2021, 44 Label Group is a fashion brand offering an effortless metropolitan style, fuelled by a deep sense of belonging transcending the gender binary. Initially presenting their new collections in Milan during men’s fashion week, the brand made a jump to Paris’ fashion calendar, presenting their latest collection in the Montparnasse district to a fashion and celebrity audience."
          }
        </Typography>
      </Section>
      <Section
        images={[
          {
            src: `${S3_BUCKET_URL}44-label-group/section-1.png`,
            alt: "Antonioli",
          },
        ]}
        maxWidth={"xl"}
      />
      <Section
        rtl
        title={"44 LABEL GROUP SS24 – ON TOUR"}
        images={[
          {
            src: `${S3_BUCKET_URL}44-label-group/tour.png`,
            alt: "Tour",
          },
        ]}
        maxWidth={"xl"}
      >
        <Typography paragraph variant="body1">
          {
            "g generation, fashionistas, influencers, established artists in multiple genres, the Rave kids, music lovers, younauthenticity of Max Kobosil is transferred to all looks and is appreciated by a broad target audience, with brand awareness growing rapidly around the whole world."
          }
        </Typography>
      </Section>
      <Section half rtl title={"COMMUNITY"} maxWidth={"xl"}>
        <Typography paragraph variant="body1">
          {
            "Thanks to Kobosil’s strong community, the brand has secured an extremely loyal following that is worth tapping into. Rave kids, music lovers, young generation, fashionistas, influencers, established artists in multiple genres, the authenticity of Max Kobosil is transferred to all looks and is appreciated by a broad target audience, with brand awareness growing rapidly around the whole world. It’s become the go-to brand for globally renowned DJs, producers and performers."
          }
        </Typography>
      </Section>
      <Section
        images={[
          {
            src: `${S3_BUCKET_URL}44-label-group/community.png`,
            alt: "Community",
          },
        ]}
        maxWidth={"xl"}
      />
      <Section
        half
        maxWidth={"xl"}
        title={"R LABEL GROUP 10 YEARS VELODROM, BERLIN"}
      />
      <Section
        images={[
          {
            src: `${S3_BUCKET_URL}44-label-group/velodrom.png`,
            alt: "Velodrom",
            caption: "44 Showcase - over 5700 fans at Velodrom, Berlin 08.07.2",
          },
        ]}
        maxWidth={"xl"}
      />

      <Section
        images={[
          {
            src: `${S3_BUCKET_URL}44-label-group/section-last.png`,
            alt: "44 Label Group",
            caption: "44 Label Group stage in Amsterdam",
          },
        ]}
        maxWidth={"xl"}
      />
    </>
  );
}
