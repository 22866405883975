import { Box } from "@mui/material";
import Section from "../../molecules/Section";
import Typography from "../molecules/Typography";

export default function Sogni() {
  // const theme = useTheme();
  // const matchesDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;
  return (
    <Box mt={12}>
      <Section half rtl maxWidth={"xl"}>
        <Typography paragraph variant="body1">
          {
            "Located in the heart of Milan, in a mid-19th century building which used to be used as a kindergarten, SOGNI covers an area of 500 sqm which includes dedicated bar space, the central feature of the locale, a large interior garden, all surrounded by a timeless atmosphere in line with the history of the place with a contemporary touch. "
          }
        </Typography>
        <Typography paragraph variant="body1">
          {
            "The Club’s Restaurant offers a fish-based menu of elevated Mediterranean cuisine. Supporting high-standard small producers, respect and love of nature and animals are at the core of this project."
          }
        </Typography>
      </Section>
      <Section
        half
        rtl
        images={[
          {
            src: `${S3_BUCKET_URL}sogni/sogni.png`,
            alt: "Sogni",
          },
        ]}
        maxWidth={"xl"}
      />
      <Section half maxWidth={"xl"} title={"RESTAURANT"}>
        <Typography paragraph variant="body1">
          {
            "At the core of Sogni’s research is Italy, in all its regions: the ingredients, rigorously selected from suppliers of the highest quality, combine naturally and tell the stories of the land and the people."
          }
        </Typography>
        <Typography paragraph variant="body1">
          {
            "Many are the aromas, testifying that excellence often lies in the simple things: the secret of our cuisine lies in a basil leaf, in a bunch of wild rocket or in the embers of our robata grill flavoured with almond, hazelnut or cherry."
          }
        </Typography>
      </Section>
      <Section
        images={[
          {
            src: `${S3_BUCKET_URL}sogni/restaurant.png`,
            alt: "Restaurant",
            caption: "Sogni’s Restaurant",
          },
          {
            src: `${S3_BUCKET_URL}sogni/restaurant-2.png`,
            alt: "Restaurant",
            caption: "Sogni’s Restaurant",
          },
        ]}
        maxWidth={"xl"}
      />
      <Section
        title={"BAR"}
        images={[
          {
            src: `${S3_BUCKET_URL}sogni/bar.png`,
            alt: "Sogni",
            caption: "Sogni’s Bar",
          },
        ]}
        maxWidth={"xl"}
      >
        <Typography paragraph variant="body1">
          {
            "Sogni’s bar embodies timeless elegance, reminiscent of early 20th-century style. With its burnished mirrors, zinc and wood bar, and inviting stools, its purpose to be a hub for stories, meetings, encounters and rich with emotions. Expert bartenders prepare signature cocktails during opening hours making it either the ideal prelude to a dinner with friends or rather the perfect setting for an after-dinner conversation."
          }
        </Typography>
      </Section>
      <Section
        rtl
        title={"SALOTTO"}
        images={[
          {
            src: `${S3_BUCKET_URL}sogni/salotto.png`,
            alt: "Sogni",
            caption: "Sogni’s Salotto",
          },
        ]}
        maxWidth={"xl"}
      >
        <Typography paragraph variant="body1">
          {
            "The main entrance hall, a welcoming and spacious room which gives access to the restaurant’s different lounges, places you immediately inside a small haven away from city chaos which exudes warmth, sophistication, and informal beauty."
          }
        </Typography>
      </Section>
      <Section
        title={"REFETTORIO"}
        images={[
          {
            src: `${S3_BUCKET_URL}sogni/refettorio.png`,
            alt: "Sogni",
            caption: "Sogni’s Refettorio",
          },
        ]}
        maxWidth={"xl"}
      >
        <Typography paragraph variant="body1">
          {
            "Sogni’s bar embodies timeless elegance, reminiscent of early 20th-century style. With its burnished mirrors, zinc and wood bar, and inviting stools, its purpose to be a hub for stories, meetings, encounters and rich with emotions. Expert bartenders prepare signature cocktails during opening hours making it either the ideal prelude to a dinner with friends or rather the perfect setting for an after-dinner conversation."
          }
        </Typography>
      </Section>
      <Section
        rtl
        title={"VERANDA"}
        images={[
          {
            src: `${S3_BUCKET_URL}sogni/veranda.png`,
            alt: "Sogni",
            caption: "Sogni’s Veranda",
          },
        ]}
        maxWidth={"xl"}
      >
        <Typography paragraph variant="body1">
          {
            "Sogni’s winter garden, the ‘Veranda’, offers a delightful dining experience amidst lush greenery where a harmonious blend of nature and cuisine awaits, and one can indulge in a cozy, elegant setting."
          }
        </Typography>
      </Section>
    </Box>
  );
}
