import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Dialog, DialogContent, DialogTitle, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Typography from "../molecules/Typography";
import Logo from "../molecules/Logo";

type PageItem = {
  slug: string;
  name: string;
};

const pages = [
  { slug: "dreamers-factory", name: "Dreamers factory" },
  { slug: "antonioli", name: "Antonioli" },
  { slug: "ann-demeulemeester", name: "Ann Demeulemeester" },
  { slug: "44-label-group", name: "44 Label Group" },
  { slug: "sogni", name: "Sogni" },
  { slug: "volt", name: "Volt" },
  { slug: "foundation", name: "Foundation" },
];

function Navbar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const theme = useTheme();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navigateToPage = (slug: string) => {
    navigate(slug);
    handleCloseNavMenu();
  };

  return (
    <AppBar
      elevation={0}
      position="fixed"
      color={"inherit"}
      sx={{
        background: "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.8) 35%, rgba(0,0,0,0) 100%);"
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Button
            onClick={() => navigateToPage("/")}
            sx={{ color: "inherit", padding: 0 }}
            disableRipple
          >
            <Box mt={3}>
              <Logo width={250} />
            </Box>
          </Button>
          <Box
            display="flex"
            justifyContent={"flex-end"}
            sx={{ flexGrow: 1, display: { xs: "none", lg: "flex" } }}
          >
            {pages.map(
              (page: PageItem) =>
                page.slug && (
                  <NavLink
                    style={({ isActive }) => ({
                      textDecoration: "none",
                      fontFamily: isActive
                        ? "HelveticaNowTextBold"
                        : "HelveticaNowTextLight",
                    })}
                    key={page.slug}
                    to={page.slug}
                  >
                    <Button
                      disableRipple
                      sx={{
                        fontFamily: "inherit",
                        px: 2,
                        color: "white",
                        display: "block",
                        paddingLeft: 1,
                        paddingRight: 1
                      }}
                    >
                      {page.name}
                    </Button>
                  </NavLink>
                )
            )}
          </Box>

          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              disableRipple
            >
              <MenuIcon />
            </IconButton>
            <Dialog
              PaperProps={{
                sx: {
                  bgcolor: theme.palette.background.default,
                  boxShadow: "none",
                  backgroundImage: "none",
                },
              }}
              fullWidth
              fullScreen
              onClose={handleCloseNavMenu}
              open={Boolean(anchorElNav)}
            >
              <DialogTitle sx={{ m: 0 }}>
                <Button
                  onClick={() => navigateToPage("/")}
                  sx={{ color: "inherit", padding: 0 }}
                  disableRipple
                >
                  <Box mt={1} ml={-1}>
                    <Logo width={250} />
                  </Box>
                </Button>
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleCloseNavMenu}
                disableRipple
                sx={{
                  position: "absolute",
                  right: 20,
                  top: 12,
                  color: (theme) => theme.palette.text.primary,
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {pages.map((page: PageItem) => (
                  <MenuItem
                    disableRipple
                    sx={{ justifyContent: "center" }}
                    key={page.slug}
                    // onClick={handleCloseNavMenu}
                    onClick={() => navigateToPage(page.slug)}
                  >
                    <Typography
                      sx={{ textTransform: "uppercase" }}
                      variant="h5"
                      color={"text.primary"}
                      textAlign="center"
                    >
                      {page.name}
                    </Typography>
                  </MenuItem>
                ))}
              </DialogContent>
            </Dialog>
            {/* <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page: PageItem) => (
                <MenuItem key={page.slug} onClick={handleCloseNavMenu}>
                  <Typography color={'text.primary'} textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu> */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
