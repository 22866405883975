// import { cyan, purple } from "@mui/material/colors";
import { CssBaseline } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/hooks/ScrollToTop";
import App from "./App";
import "./index.css";
import "./fonts/HelveticaNowText-Black Italic.ttf";
import "./fonts/HelveticaNowText-Black.ttf";
import "./fonts/HelveticaNowText-Bold Italic.ttf";
import "./fonts/HelveticaNowText-Bold.ttf";
import "./fonts/HelveticaNowText-Extra Bold Italic.ttf";
import "./fonts/HelveticaNowText-Extra Bold.ttf";
import "./fonts/HelveticaNowText-Extra Light Italic.ttf";
import "./fonts/HelveticaNowText-Extra Light.ttf";
import "./fonts/HelveticaNowText-Light Italic.ttf";
import "./fonts/HelveticaNowText-Light.ttf";
import "./fonts/HelveticaNowText-Medium Italic.ttf";
import "./fonts/HelveticaNowText-Medium.ttf";
import "./fonts/HelveticaNowText-Regular Italic.ttf";
import "./fonts/HelveticaNowText-Regular.ttf";
import "./fonts/HelveticaNowText-Thin Italic.ttf";
import "./fonts/HelveticaNowText-Thin.ttf";

import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
let theme = createTheme();
theme = createTheme(theme, {
  typography: {
    fontFamily: ["HelveticaNowTextRegular"].join(","),
    h1: {},
    h2: {
      fontSize: "1.8rem",
      "@media (min-width: 600px)": {
        fontSize: "3.5rem",
      },
    },
    h3: {},
    h4: {
      textTransform: "uppercase",
      fontSize: "1.7rem",
      "@media (min-width: 600px)": {
        fontSize: "2.2rem",
      },
    },
    h5: {},
    h6: {},
    body1: {
      lineHeight: 1.1,
    },
    body2: {
      fontSize: ".8rem",
    },
    button: {
      fontFamily: "HelveticaNowTextLight",
    },
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiDialog: {},
    MuiButton: {
      styleOverrides: {
        root: {},
        outlined: {},
        contained: {
          // border: '2px solid',
        },
      },
    },
  },
  mixins: {
    toolbar: {
      "@media (min-width: 600px)": {
        minHeight: 90,
      },
    },
  },

  palette: {
    mode: "dark",
    background: {
      default: "#0a0a0b",
      paper: "#0a0a0b",
    },
    primary: {
      main: "#ffffff",
      light: "#4a12fc",
      dark: "#0000a3",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ffffff",
      dark: "#ffffff",
      contrastText: "#000000",
    },
    text: {
      primary: "#ffffff",
      secondary: "#ffffff",
    },
    divider: "#0a0a0b",
  },
});
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
