import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as React from "react";
// import { useNavigate } from "react-router-dom";
import Typography from "../molecules/Typography";
import Logo from "../molecules/Logo";
import { Link } from "react-router-dom";

type Props = {};

const footerData = [
  {
    title: "BRANDS",
    items: [
      { title: "Antonioli", link: "/antonioli", target: "_self" },
      {
        title: "44 Label Group",
        link: "/44-label-group",
        target: "_self",
      },
      {
        title: "Ann Demeulemeester",
        link: "/ann-demeulemeester",
        target: "_self",
      },
      { title: "Sogni", link: "/sogni", target: "_self" },
      { title: "Volt", link: "/volt", target: "_self" },
    ],
  },
  {
    title: "CONTACTS",
    items: [
      {
        title: "Press",
        link: "mailto:press@antonioligroup.com",
        target: "_blank",
      },
      { title: "General", link: "contact", target: "_self" },
      {
        title: "Foundation",
        link: "mailto:info@oasimuso.org",
        target: "_blank",
      },
      // { title: "Careers", link: "mailto:info@oasimuso.org " },
    ],
  },
  {
    title: "CAREERS",
    items: [
      {
        title: "Work with us",
        link: "https://antonioli.arca24.careers/jobs.php",
        target: "_self",
      },
    ],
  },
];

const Footer: React.FC<Props> = () => {
  const theme = useTheme();
  const matchesDownMd = useMediaQuery(theme.breakpoints.down("md"));
  // const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const mobileView = React.useMemo(() => {
    return footerData.map((data) => {
      return (
        <Accordion
          key={data.title}
          sx={{ backgroundColor: "transparent" }}
          elevation={0}
          disableGutters
          expanded={expanded === data.title}
          onChange={handleChange(data.title)}
        >
          <AccordionSummary
            sx={{ padding: 0, minHeight: 42 }}
            aria-controls={data.title}
            id={data.title}
          >
            <Typography
              fontFamilyStr="Medium"
              variant="body2"
              sx={{ width: "33%", flexShrink: 0 }}
            >
              {data.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            {data.items.map((item) => {
              return (
                <Link
                  key={item.title}
                  to={item.link}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Typography variant="body2" fontFamilyStr="Light">
                    {item.title}
                  </Typography>
                </Link>
              );
            })}
          </AccordionDetails>
        </Accordion>
      );
    });
  }, [expanded]);

  const desktopView = React.useMemo(() => {
    return (
      <Grid container spacing={2}>
        {footerData.map((data) => {
          return (
            <Grid key={data.title} item xs={4}>
              <Typography gutterBottom fontFamilyStr="Medium" variant="body2">
                {data.title}
              </Typography>
              {data.items.map((item) => {
                return (
                  <Link
                    key={item.title}
                    to={item.link}
                    target={item?.target || "_self"}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Typography variant="body2" fontFamilyStr="Light">
                      {item.title}
                    </Typography>
                  </Link>
                );
              })}
            </Grid>
          );
        })}
      </Grid>
    );
  }, []);

  return (
    <Container maxWidth="xl">
      <Box paddingY={5}>
        <Grid container spacing={5}>
          {matchesDownMd && (
            <Grid item xs={12} md={4}>
              {mobileView}
            </Grid>
          )}
          <Grid item xs={12} sm={5} md={6} lg={7} xl={8}>
            <Box>
              <Logo width={200} />
            </Box>
            {!matchesDownMd && <Box my={7} />}
            <Typography
              fontFamilyStr="Light"
              variant="body2"
            >{`Copyright © ${new Date().getUTCFullYear()} Antonioli Group. ${
              matchesDownMd ? "All right reserved" : ""
            }`}</Typography>
            {!matchesDownMd && (
              <Typography fontFamilyStr="Light" variant="body2">
                {"All right reserved"}
              </Typography>
            )}
          </Grid>
          {!matchesDownMd && (
            <Grid item xs={12} sm={7} md={6} lg={5} xl={4}>
              {desktopView}
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  );
};
export default Footer;
