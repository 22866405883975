import {
  Breakpoint,
  Container,
  Box,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SxProps } from "@mui/system";
import React from "react";
import Typography from "../components/molecules/Typography";
import Carousel from "../components/organisms/Carousel";
import { Image } from "../types";

type SectionProps = {
  sx?: SxProps;
  children?: React.ReactNode;
  maxWidth?: Breakpoint | false;
  images?: Array<Image>;
  title?: string;
  rtl?: boolean;
  half?: boolean;
};

const Section: React.FC<SectionProps> = ({
  maxWidth,
  children,
  sx,
  images,
  title,
  half,
  rtl = false,
}) => {
  const theme = useTheme();
  const matchesDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const hasCopy = (children && children !== undefined) || title !== undefined;

  const gallery = React.useMemo(() => {
    if (!images || images?.length === 0) return;
    return (
      <Grid item xs={12} md={hasCopy || half ? 6 : 12}>
        <Box
          {...(rtl
            ? { ml: matchesDownMd || !hasCopy ? 0 : 4 }
            : { mr: matchesDownMd || !hasCopy ? 0 : 4 })}
        >
          {images?.length === 1 ? (
            <>
              <img width="100%" src={images[0].src} alt={images[0]?.alt} />
              {images[0]?.alt && (
                <Typography paragraph variant="body1">
                  {images[0]?.caption}
                </Typography>
              )}
            </>
          ) : (
            <Carousel images={images} />
          )}
        </Box>
      </Grid>
    );
  }, [half, hasCopy, images, matchesDownMd, rtl]);

  return (
    <Container maxWidth={maxWidth} sx={sx}>
      <Grid container flexDirection={rtl ? "row-reverse" : "row"}>
        {gallery}
        <Grid item xs={12} md={(images && images.length > 0) || half ? 6 : 12}>
          <Box>
            {title && (
              <Typography paragraph fontFamilyStr="Medium" variant="h4">
                {title}
              </Typography>
            )}
            {children && children}
          </Box>
        </Grid>
      </Grid>
      <Box mb={5} />
    </Container>
  );
};

export default Section;
