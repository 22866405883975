import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import Layout from "../../molecules/Layout";
import Section from "../../molecules/Section";
import Typography from "../molecules/Typography";

export default function Home() {
  const theme = useTheme();
  const matchesDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;
  return (
    <>
      <Layout
        images={[
          {
            src: `${S3_BUCKET_URL}homepage/cover.png`,
            alt: "Antonioli Boutique",
          },
          {
            src: `${S3_BUCKET_URL}homepage/cover2.png`,
            alt: "Antonioli Boutique",
          },
          {
            src: `${S3_BUCKET_URL}homepage/cover3.png`,
            alt: "Antonioli Boutique",
          },
        ]}
        maxWidth="xl"
      >
        <Stack justifyContent={'center'} sx={{marginBottom: 6}} direction={matchesDownMd ? "column" : "row"} spacing={5}>
          <Box mb={2}   maxWidth={450}>
            <Typography fontFamilyStr="Medium" variant="h2">
              {"CURATION"}
            </Typography>
            <Typography variant="body1">
              {
                "Antonioli offers the most complete and essential selection of luxury brands and avant-garde designers."
              }
            </Typography>
          </Box>
          <Box mb={2}  maxWidth={450}>
            <Typography fontFamilyStr="Medium" variant="h2">
              {"CREATIVITY"}
            </Typography>
            <Typography variant="body1">
              {
                "Antonioli is a gallery of timeless trends, a place of style storytelling."
              }
            </Typography>
          </Box>
          <Box mb={2}  maxWidth={450}>
            <Typography fontFamilyStr="Medium" variant="h2">
              {"EXCELLENCE"}
            </Typography>
            <Typography variant="body1">
              {
                "Antonioli is a retail excellence and luxury platform that combines research, modernity and sophistication."
              }
            </Typography>
          </Box>
        </Stack>
      </Layout>
      <Section half maxWidth={"xl"}>
        <Typography variant={"body1"}>
          {
            "Antonioli is a world-renowned retailer and one of the Italian leaders in high-fashion retail, primarily known for being a pioneer in introducing new trends and brands to the Italian and, later on, worldwide market. Antonioli has always contributed to the growth and consolidation of the international fashion landscape. We provide an avant-garde selection of over 200 luxury brands dedicated to a fashion- conscious clientele."
          }
        </Typography>
      </Section>
      <Section
        maxWidth={"xl"}
        images={[
          {
            src: `${S3_BUCKET_URL}homepage/section-1${matchesDownMd ? '-mobile': ""}.png`,
            alt: "Antonioli Boutique",
            caption: "Antonioli Boutique, Milan",
          },
        ]}
      />

      <Section maxWidth={"xl"}>
        <Box my={3} display="flex" justifyContent={"center"} width={"100%"}>
          <Box maxWidth={800}>
            <Stack
              direction={matchesDownMd ? 'column' : "row"}
              spacing={5}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Link to="/antonioli">
                <img
                  height={35}
                  src={`${S3_BUCKET_URL}homepage/loghi/ANTONIOLI_LOGO_WHITE.png`}
                  alt={"antonioliGroup"}
                />
              </Link>
              <Link to="/ann-demeulemeester">
                <img
                  height={20}
                  src={`${S3_BUCKET_URL}homepage/loghi/logo Ann Demeulemeester white.png`}
                  alt={"Ann Demeulemeester"}
                />
              </Link>
              <Link to="/44-label-group">
                <img
                  height={20}
                  src={`${S3_BUCKET_URL}homepage/loghi/44_LABELGROUP_transparent_white.png`}
                  alt={"44 label group"}
                />
              </Link>
              <Link to="/sogni">
                <img
                  height={20}
                  src={`${S3_BUCKET_URL}homepage/loghi/SOGNI_LOGO.png`}
                  alt={"Sogni"}
                />
              </Link>
              <Link to="/volt">
                <img
                  height={30}
                  src={`${S3_BUCKET_URL}homepage/loghi/logo volt_BIANCO.png`}
                  alt={"Volt"}
                />
              </Link>
            </Stack>
          </Box>
        </Box>
      </Section>
    </>
  );
}
