import { Box } from "@mui/material";
import Section from "../../molecules/Section";
import ContactFormWithAttachment from "../organisms/ContactFormWithAttachment";

export default function General() {
  // const theme = useTheme();
  return (
    <>
      <Box mt={12}>
        <Section half rtl maxWidth={"xl"}>
          <ContactFormWithAttachment />
        </Section>
      </Box>
    </>
  );
}
