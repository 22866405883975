// src/FileUploadForm.js
import { Box, Button, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import Typography from "../molecules/Typography";
const ContactFormWithAttachment = () => {
  const [succesMessage, setSuccesMessage] = useState("");
  // const [formData, setFormData] = useState({
  //   name: "",
  //   email: "",
  //   file: null,
  // });

  // const handleChange = (e) => {
  //   const { name, value, files } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: files ? files[0] : value,
  //   });
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Fake call');
    setSuccesMessage("Your message has been sent successfully");
  }
  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!formData.file) {
  //     alert("Please select a file to upload");
  //     return;
  //   }

  //   const reader = new FileReader();
  //   reader.readAsDataURL(formData.file);

  //   reader.onloadend = async () => {
  //     const fileContent = reader.result.split(",")[1]; // Get base64 string

  //     const data = {
  //       name: formData.name,
  //       email: formData.email,
  //       fileName: formData.file.name,
  //       fileContent: fileContent,
  //     };

  //     try {
  //       const response = await fetch(
  //         "https://vnmkjj9b44.execute-api.eu-central-1.amazonaws.com/send-email",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             "Access-Control-Allow-Origin":
  //               "https://antonioligroup.kodo-dev.com",
  //             Accept: "application/json",
  //             // "Access-Control-Allow-Headers": "X-Requested-With,content-type",
  //             // "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
  //           },
  //           body: JSON.stringify(data),
  //         }
  //       );
  //       const result = await response.json();
  //       console.log(result);
  //     } catch (error) {
  //       // FAKE SUCCESS
  //       setSuccesMessage("Your message has been sent successfully");
  //       console.error("Error:", error);
  //     }
  //   };
  // };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            required
            name="firstname"
            label="First name"
            fullWidth
            helperText="Insert first name here"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            name="lastname"
            fullWidth
            label="Last name"
            helperText="Insert last name here"
          />
        </Grid>
      </Grid>
      <Box my={3} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            name="email"
            label="Email"
            fullWidth
            helperText="Insert first name here"
          />
        </Grid>
      </Grid>
      <Box my={3} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="topic"
            label="Topic"
            fullWidth
            helperText="Insert topic here"
          />
        </Grid>
      </Grid>
      <Box my={3} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            multiline
            rows={6}
            name="message"
            label="Message"
            fullWidth
            helperText="Insert tour message here"
          />
        </Grid>
      </Grid>
      <Box my={3} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button type="submit" color="secondary" variant="contained">
            {"SUBMIT"}
          </Button>
        </Grid>
      </Grid>

      {succesMessage && (
        <>
          <Box my={3} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={"h6"}>{succesMessage}</Typography>
            </Grid>
          </Grid>
        </>
      )}

      {/* <div>
        <label>Name:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>Email:</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label>File:</label>
        <input type="file" name="file" onChange={handleChange} required />
      </div>
      <button type="submit">Submit</button> */}
    </form>
  );
};

export default ContactFormWithAttachment;
