import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import NavBar from "./components/organisms/Navbar";
import AnnDemeulemeester from "./components/pages/AnnDemeulemeester";
import Antonioli from "./components/pages/Antonioli";
import Foundation from "./components/pages/Foundation";
import General from "./components/pages/General";
import Home from "./components/pages/Home";
import LabelGroup from "./components/pages/LabelGroup";
import NotFound from "./components/pages/NotFound";
import Sogni from "./components/pages/Sogni";
import Volt from "./components/pages/Volt";

import Footer from "./components/organisms/Footer";
import DreamersFactory from "./components/pages/DreamersFactory";
function App() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<Home />} />
        <Route path="/antonioli" element={<Antonioli />} />
        <Route path="/44-label-group" element={<LabelGroup />} />
        <Route path="/volt" element={<Volt />} />
        <Route path="/foundation" element={<Foundation />} />
        <Route path="/sogni" element={<Sogni />} />
        <Route path="/dreamers-factory" element={<DreamersFactory />} />
        <Route path="/contact" element={<General />} />
        <Route path="/ann-demeulemeester" element={<AnnDemeulemeester />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
