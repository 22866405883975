// create a typography component that will be used in the project and take "bold", "italic" and "regular" as props

import { SxProps } from "@mui/material";
import React from "react";

interface LogoProps {
  width?: number;
  sx?: SxProps;
}

const Logo: React.FC<LogoProps> = ({ width = 100, ...sx }) => {
  return (
    <img
      width={width}
      {...sx}
      src="https://antonioli-frontend.s3.amazonaws.com/logo-white.png"
      alt="Antonioli"
    />
  );
};

export default Logo;
