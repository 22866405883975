import { Box } from "@mui/material";
import Section from "../../molecules/Section";
import Typography from "../molecules/Typography";

export default function Foundation() {
  // const theme = useTheme();
  // const matchesDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;
  return (
    <Box mt={12}>
      <Section half rtl maxWidth={"xl"}>
        <Typography paragraph variant="body1">
          {
            "Corporate Social Responsibility to us means working towards providing a balanced & loving support framework for animals & humankind alike."
          }
        </Typography>
        <Typography paragraph variant="body1">
          {
            "The Foundation pursues non-profit, civic, utilitarian and socially useful purposes, with special reference to the protection and promotion of the environment and animals. We focus on making a positive impact, we care deeply about our communities and our environment, so we work hard to protect and improve them. One big part of what we do is supporting projects that help animals that have been through a very difficult time through no fault of their own. In particular, the Claudio Antonioli Foundation intends to promote the establishment of shelter and support facilities for animals, first and foremost dogs, abandoned or otherwise in situations of need."
          }
        </Typography>
      </Section>
      <Section
        half
        rtl
        images={[
          {
            src: `${S3_BUCKET_URL}foundation/dogs.png`,
            alt: "Foundation",
          },
        ]}
        maxWidth={"xl"}
      />
      <Section half maxWidth={"xl"} title={"OASI MUSO"}>
        <Typography paragraph variant="body1">
          {
            "Dedicated to building shelters and providing support for these animals in need."
          }
        </Typography>
        <Typography paragraph variant="body1">
          {
            "One of our main goals is to create safe spaces for animals, especially dogs, who have been abandoned or are in need of help. We believe every animal deserves a loving home and a chance to live a happy life. That’s why we’re dedicated to building shelters and providing support for these animals in need."
          }
        </Typography>
        <Typography paragraph variant="body1">
          {
            "We focus on making a positive impact, we care deeply about our communities and our environment, so we work hard to protect and improve them. One big part of what we do is supporting projects that help animals that have been through a very difficult time through no fault of their own. In particular, the Claudio Antonioli Foundation intends topromote the establishment of shelter and support facilities for animals, first and foremost dogs, abandoned or otherwise in situations of need."
          }
        </Typography>
      </Section>
      <Section
        images={[
          {
            src: `${S3_BUCKET_URL}foundation/muso.png`,
            alt: "Oasi Muso",
          },
        ]}
        maxWidth={"xl"}
      />
    </Box>
  );
}
