import { Box } from "@mui/material";
import Section from "../../molecules/Section";
import Typography from "../molecules/Typography";

export default function DreamersFactory() {
  // const theme = useTheme();
  // const matchesDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET_URL;
  return (
    <Box mt={12}>
      <Section half rtl maxWidth={"xl"}>
        <Typography paragraph variant="body1">
          {
            "Dreamers Factory is the platform through which ANTONIOLI GROUP provides talented visionaries, artists and designers with the backing, the structure and support necessary, providing them with the know-how and the competences to unleash their full potential and realize their creative dreams. Among Dreamers Projects are of course ANTONIOLI, the iconic Belgian brand ANN DEMEULEMEESTER, 44 LABEL GROUP, SOGNI, VOLT CLUB , and VLT talent agency, with other upcoming projects to be launched in the near future."
          }
        </Typography>
      </Section>
      <Section
        half
        rtl
        images={[
          {
            src: `${S3_BUCKET_URL}dreamers-factory/dreamers-factory.png`,
            alt: "Dreamers Factory",
          },
        ]}
        maxWidth={"xl"}
      />
      
    </Box>
  );
}
