import { Box, IconButton } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import { Image } from "../../types";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

type ArrowProps = {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};
type CarouselProps = {
  images?: Array<Image>;
  disableArrows?: boolean;
  children?: React.ReactNode;
  coverMode?: boolean;
};

function NextArrow(props: ArrowProps) {
  const { style, onClick } = props;
  return (
    <IconButton
      disableRipple
      sx={{
        ...style,
        color: "white",
        position: "absolute",
        top: "100%",
        right: 0,
        left: "auto",
      }}
      onClick={onClick}
    >
      <ArrowForwardIosIcon fill="inherit" />
    </IconButton>
  );
}

function PrevArrow(props: ArrowProps) {
  const { style, onClick } = props;
  return (
    <IconButton
      disableRipple
      sx={{
        ...style,
        color: "white",
        position: "absolute",
        top: "100%",
        right: 40,
        left: "auto",
      }}
      onClick={onClick}
    >
      <ArrowBackIosIcon fill="inherit" />
    </IconButton>
  );
}

const Carousel: React.FC<CarouselProps> = ({
  images,
  children,
  disableArrows = false,
  coverMode = false,
}) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: !disableArrows ? <NextArrow /> : <></>,
    prevArrow: !disableArrows ? <PrevArrow /> : <></>,
  };

  if (children) {
    return (
      <Box position={"relative"}>
        <Slider {...settings}>
          {images?.map((image: Image, index: number) => {
            return (
              <div key={index} style={{ width: "100%", height: "100vh" }}>
                <div
                  key={index}
                  {...(image && {
                    style: {
                      backgroundImage: `url(${image.src})`,
                      backgroundPosition: "top",
                      backgroundSize: "cover",
                      height: "100vh",
                      width: "100%",
                    },
                  })}
                />
              </div>
            );
          })}
        </Slider>
        
          {children}
      </Box>
    );
  }

  return (
    <Box>
      <Slider {...settings}>
        {images?.map((image: Image, index: number) => {
          return (
            <div key={index}>
              <img width="100%" src={image?.src} alt={image?.alt} />
            </div>
          );
        })}
      </Slider>
    </Box>
  );
};

export default Carousel;
